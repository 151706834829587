import React from 'react';
import './index.scss';
import iconAppStore from '../../images/icon-app-store.svg';
import iconGooglePlay from '../../images/icon-google-play.svg';
import { useTranslation } from 'react-i18next';

const FooterComponent = props => {
  const { t } = useTranslation();
  return (
    <div
      className="footer"
      style={{
        backgroundColor: props.colors.background_2,
      }}
    >
      <div className="footer__container">
        <div
          className="footer__copyright"
          style={{
            color: props.colors.navigation_1,
          }}
        >
          {window.parametrize('REACT_APP_FOOTER_INFO') ||
            t('account.footer.footer__copyright')}
        </div>
        <div className="footer__links-container">
          {window.parametrize('REACT_APP_POLICY_LINK') && (
            <a
              className="footer__links-container__item"
              style={{
                color: props.colors.primary,
              }}
              href={window.parametrize('REACT_APP_POLICY_LINK')}
            >
              {t('account.footer.footer__links_container__item.privacy_policy')}
            </a>
          )}
          {window.parametrize('REACT_APP_OFERTA_LINK') && (
            <a
              className="footer__links-container__item"
              style={{
                color: props.colors.primary,
              }}
              href={window.parametrize('REACT_APP_OFERTA_LINK')}
            >
              {t('account.footer.footer__links_container__item.public_offer')}
            </a>
          )}
          {(window.parametrize('REACT_APP_APPSTORE_LINK') ||
            window.parametrize('REACT_APP_GOOGLEPLAY_LINK')) && (
            <div className="footer__links-container-icons">
              {window.parametrize('REACT_APP_APPSTORE_LINK') && (
                <a
                  className="footer__links-container__item-icon"
                  href={window.parametrize('REACT_APP_APPSTORE_LINK')}
                >
                  <img
                    className="footer__links-container-icon footer__links-container-icon_app-store"
                    src={iconAppStore}
                    alt={t(
                      'account.footer.footer__links-container_icon_footer__links_container_icon_app_store.app_store_icon',
                    )}
                  />
                </a>
              )}
              {window.parametrize('REACT_APP_GOOGLEPLAY_LINK') && (
                <a
                  className="footer__links-container__item-icon"
                  href={window.parametrize('REACT_APP_GOOGLEPLAY_LINK')}
                >
                  <img
                    className="footer__links-container-icon"
                    src={iconGooglePlay}
                    alt={t(
                      'account.footer.footer__links-container_icon_footer__links_container_icon_app_store.google_play_icon',
                    )}
                  />
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
