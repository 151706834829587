import CyrillicToTranslit from 'cyrillic-to-translit-js';

const cyrillicToTranslit = new CyrillicToTranslit();

export const transliterate = string => {
  const strTrans = cyrillicToTranslit.transform(string);
  const res = strTrans.charAt(0).toUpperCase() + strTrans.slice(1);

  if (strTrans.length === 0) {
    return strTrans;
  } else {
    return res;
  }
};
