import HappyTickets from 'figma/components/Illustration/happy-tickets/HappyTickets/HappyTickets';
import { useTranslation } from 'react-i18next';
import React from 'react';
import styles from './index.module.scss';

const PlaceholderComponent = props => {
  const { t } = useTranslation();
  return (
    <div className={styles.placeholder}>
      <div className={styles.placeholder__back} />
      <HappyTickets
        styles={{
          fanThemeClubNavigation1: props.fanThemeClubNavigation1,
          fanThemeClubPrimaryLight: props.fanThemeClubPrimaryLight,
        }}
      />

      <div className={styles.placeholder__title}>
        {t('inventory.place_holder_component.cart_still_empty')}
      </div>
      <div className={styles.placeholder__text}>
        {t('inventory.place_holder_component.hurry_choose_best_places')}
      </div>
      <div
        className={styles.placeholder__link}
        onClick={() => {
          if (props.history) props.history.push('');
          const url = window.parametrize('REACT_APP_SHOP_URL');
          window.location.href = url;
        }}
      >
        {t('inventory.place_holder_component.go_store')}
      </div>
    </div>
  );
};

export default PlaceholderComponent;
