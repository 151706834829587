import React from 'react';
import PersonalInfoField from './PersonalInfoField';
import PersonalInfoConfirmableField from './PersonalInfoConfirmableField';
import { withFormik } from 'formik';
import { Form, Button, message, DatePicker } from 'antd';
import * as yup from 'yup';
import wait from 'waait';
import { pathOr } from 'ramda';
import moment from 'moment';
import { transliterate } from '../../../../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const PersonalInfo = props => {
  const { t } = useTranslation();
  return (
    <Form onSubmit={props.handleSubmit} className="personal-info__form">
      <h2>
        {t('account_panel.personal_info.personal_info__form.h2.personal_data')}
      </h2>
      <PersonalInfoField
        setFieldValue={props.setFieldValue}
        name="name"
        label={t(
          'account_panel.personal_info.personal_info__form.personal_info_field.name',
        )}
        value={props.values.name}
      />
      <PersonalInfoField
        setFieldValue={props.setFieldValue}
        name="surname"
        label={t(
          'account_panel.personal_info.personal_info__form.personal_info_field.surname',
        )}
        value={props.values.surname}
      />
      <PersonalInfoField
        setFieldValue={props.setFieldValue}
        name="patronymic"
        label={t(
          'account_panel.personal_info.personal_info__form.personal_info_field.patronymic',
        )}
        value={props.values.patronymic}
      />
      <PersonalInfoField
        setFieldValue={props.setFieldValue}
        name="birthday"
        label={t(
          'account_panel.personal_info.personal_info__form.personal_info_field.date_of_birth',
        )}
        value={moment(props.values.birthday)}
        component={DatePicker}
        componentProps={{
          format: 'DD.MM.YYYY',
          className: 'input-field',
        }}
      />
      <h2>
        {t('account_panel.personal_info.personal_info__form.h2.contacts')}
      </h2>
      <PersonalInfoConfirmableField
        label="Email"
        name="email"
        value={props.values.email}
        isConfirmed={props.values.isEmailConfirmed}
        {...props}
      />
      <PersonalInfoConfirmableField
        label={t(
          'account_panel.personal_info.personal_info__form.personal_info_field.mobile_phone',
        )}
        name="phone"
        value={props.values.phone}
        isConfirmed={props.values.isPhoneConfirmed}
        {...props}
      />
      <Button
        id="PersonalInfoSaveButton"
        htmlType="submit"
        disabled={props.values.isButtonDisabled}
        className="personal-info__btn"
      >
        {t('account_panel.personal_info.personal_info__form.button')}
      </Button>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: props => {
    const mappedPerson = path => pathOr({}, ['mappedPerson', ...path], props);
    return {
      name: mappedPerson(['name']),
      surname: mappedPerson(['surname']),
      patronymic: mappedPerson(['patronymic']),
      birthday: moment(mappedPerson(['birthday'])),
      email: mappedPerson(['email']),
      isEmailConfirmed: mappedPerson(['isEmailConfirmed']),
      phone: mappedPerson(['phone']),
      isPhoneConfirmed: mappedPerson(['isPhoneConfirmed']),
      isButtonDisabled: false,
    };
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email(
        t('account_panel.personal_info.with_formik.validation_schema.email'),
      )
      .required(
        t('account_panel.personal_info.with_formik.validation_schema.required'),
      ),
  }),
  handleSubmit: async (values, { props, setFieldValue, setErrors }) => {
    setFieldValue('isButtonDisabled', true);
    const birthday = moment(values.birthday).format('YYYY-MM-DD');
    const loading = message.loading(
      t(
        'account_panel.personal_info.with_formik.handle_submit.message_loading',
      ),
    );
    try {
      await props
        .updateProfile({
          variables: {
            user: {
              person: {
                name: {
                  ru: values.name,
                  en: transliterate(values.name),
                },
                surname: {
                  ru: values.surname,
                  en: transliterate(values.surname),
                },
                patronymic: {
                  ru: values.patronymic,
                  en: transliterate(values.patronymic),
                },
                birthday,
                contacts: [
                  {
                    type: 'EMAIL',
                    value: values.email,
                  },
                  {
                    type: 'PHONE',
                    value: values.phone,
                  },
                ],
              },
            },
            email: values.email,
            confirmationResultUrl: window.parametrize('REACT_APP_CONFIRM_URL'),
          },
          update: async (cache, { data }) => {
            message.success(
              t(
                'account_panel.personal_info.with_formik.handle_submit.message_success.email_updated_successfully_sent_email',
              ),
            );
            message.success(
              t(
                'account_panel.personal_info.with_formik.handle_submit.message_success.profile_updated_successfully',
              ),
            );
            const nextTryTime = pathOr(
              5666,
              ['contacts', 'sendEmailConfirmationCode', 'nextTryAfterMsecs'],
              data,
            );
            await wait(nextTryTime);
            setFieldValue('isButtonDisabled', false);
          },
        })
        .catch(async err => {
          if (err.message.includes('uid=9bd114c9')) {
            message.success(
              t(
                'account_panel.personal_info.with_formik.handle_submit.message_success.email_updated_successfully',
              ),
            );
          }
          setFieldValue('isButtonDisabled', false);
        });
    } catch (e) {
      message.error(JSON.stringify(e));
      setFieldValue('isButtonDisabled', false);
    } finally {
      loading();
    }
  },
  validateOnBlur: false,
  validateOnChange: false,
  displayName: 'PersonalInfoForm',
})(PersonalInfo);
