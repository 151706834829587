import React from 'react';
import { Typography, Input, Popover } from 'antd';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const PersonalInfoConfirmableField = props => {
  const { t } = useTranslation();
  const suffix = props.isConfirmed ? (
    <Popover
      title={null}
      content={
        <span>
          {t(
            'account_panel.personal_info.personal_info_confirmable_field.span.confirmed',
          )}
        </span>
      }
    >
      <Icon type="check" style={{ color: 'green' }} />
    </Popover>
  ) : (
    <Popover
      title={null}
      content={
        <span>
          {t(
            'account_panel.personal_info.personal_info_confirmable_field.span.not_confirmed',
          )}
        </span>
      }
    >
      <Icon type="close" style={{ color: 'tomato' }} />
    </Popover>
  );
  return (
    <Typography className="df jcfs aic personal-info__field">
      <Text type="secondary">{props.label}</Text>
      <div className="df fdc">
        <div className="df aic">
          <Input
            className="input-field"
            suffix={suffix}
            name={props.name}
            value={props.value}
            onChange={e => {
              const value = e.target.value;
              props.setFieldValue(props.name, value);
            }}
          />
        </div>

        {props.touched[props.name] && props.errors[props.name] ? (
          <Text type="danger">{props.errors[props.name]}</Text>
        ) : null}
      </div>
    </Typography>
  );
};

export default PersonalInfoConfirmableField;
