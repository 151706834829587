import React from 'react';

const Cross = props => {
  return (
    <svg
      className={props.className}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.236634 8.57446C-0.0727293 8.88368 -0.0874609 9.43586 0.244 9.75244C0.560729 10.0838 1.12053 10.069 1.42989 9.75981L5.00231 6.18905L8.57472 9.75981C8.89145 10.0764 9.43652 10.0838 9.75325 9.75244C10.0847 9.43586 10.0773 8.88368 9.76061 8.5671L6.1882 4.99635L9.76061 1.43296C10.0773 1.10901 10.0847 0.564198 9.75325 0.247616C9.43652 -0.0836908 8.89145 -0.0763284 8.57472 0.240254L5.00231 3.81101L1.42989 0.240254C1.12053 -0.068966 0.560729 -0.0836908 0.244 0.247616C-0.0874609 0.564198 -0.0727293 1.11638 0.236634 1.4256L3.80905 4.99635L0.236634 8.57446Z"
        fill={props.color}
      />
    </svg>
  );
};

export default Cross;
