import i18next from 'i18next';
import React, { useState } from 'react';
import {} from '../../../graphql';
import './index.scss';
import { pathOr, includes, isEmpty } from 'ramda';
import { Modal, message } from 'antd';
import Icon from '@ant-design/icons';
import InputMask from 'react-input-mask';
import {
  UPDATE_USER,
  SEND_CONFIRM_CODE,
  CONFIRM_CODE,
} from '../../../graphql/mutation';
import { transliterate } from '../../../../helpers';
import Cross from './Icons/Cross';
import { useTranslation } from 'react-i18next';

const EditModalComponent = props => {
  const [operationId, setOperationId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verifiedPhone, setVerifiedPhone] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState(props.profile.email);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const resetState = () => {
    setOperationId('');
    setPhoneNumber('');
    setVerifiedPhone('');
    setName('');
    setSurname('');
    setEmail(props.profile.email);
  };

  const isEdited = () => {
    return Boolean(verifiedPhone || name || surname || email);
  };

  const updateProfile = async () => {
    let fallbackLng = i18next.options.fallbackLng;
    console.log(fallbackLng);
    try {
      setLoading(true);
      let contacts = [];
      const isValidEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        email,
      );

      if (verifiedPhone) {
        contacts.push({
          type: 'PHONE',
          value: verifiedPhone,
        });
      }
      if (isValidEmail) {
        contacts.push({
          type: 'EMAIL',
          value: email,
        });
      }
      if (!verifiedPhone) {
        contacts.push({
          type: 'PHONE',
          value: props.profile.phone,
        });
      }
      if (!isValidEmail) {
        throw new Error();
      }
      await props.client.query({
        query: UPDATE_USER,
        fetchPolicy: 'no-cache',
        variables: {
          user: {
            // login: verifiedPhone || undefined,
            person: {
              name: name
                ? {
                    ru: name,
                    en: transliterate(name),
                  }
                : undefined,
              surname: surname
                ? {
                    ru: surname,
                    en: transliterate(surname),
                  }
                : undefined,
              contacts: isEmpty(contacts) ? undefined : contacts,
            },
          },
        },
      });
      message.success(t('profile.update_profile.message_success'));
      setLoading(false);
      props.setProfile({
        name: name || props.profile.name,
        surname: surname || props.profile.surname,
        email: (isValidEmail && email) || props.profile.email,
        phone: phoneNumber || props.profile.phone,
        id: props.profile.id,
      });
      if (name) {
        props.setName(name);
      }
      props.onCancel();
    } catch (e) {
      message.error(t('profile.update_profile.message_error'));
      console.error(e);
      setLoading(false);
    }
  };

  const sendCode = async () => {
    try {
      const res = await props.client.query({
        query: SEND_CONFIRM_CODE,
        fetchPolicy: 'no-cache',
        variables: {
          phoneNumber,
        },
      });

      const id = pathOr(
        '',
        ['data', 'contacts', 'sendPhoneConfirmationCode', 'operationId'],
        res,
      );

      setOperationId(id);

      message.success(t('profile.send_code.message_success.SMS_code_sent'));
    } catch (e) {
      if (includes('Cannot send confirmation', e.message)) {
        message.error(t('profile.send_code.message_error.cant_send_sms_often'));
      }
      if (includes('already confirmed for user', e.message)) {
        setVerifiedPhone(phoneNumber);
        message.success(
          t('profile.send_code.message_success.phone_number_verified'),
        );
      } else {
        message.error(t('profile.send_code.message_error.SMS_sending_error'));
        console.error(e);
      }
    }
  };

  const verifyCode = async code => {
    try {
      await props.client.query({
        query: CONFIRM_CODE,
        fetchPolicy: 'no-cache',
        variables: {
          operationId,
          code,
        },
      });

      setOperationId(undefined);
      setVerifiedPhone(phoneNumber);

      message.success(t('profile.verify_code.message_success'));
    } catch (e) {
      message.error(t('profile.verify_code.message_error'));
      console.error(e);
    }
  };

  return (
    <Modal
      visible={props.visible}
      centered
      className="edit-modal"
      bodyStyle={{
        backgroundColor: props.colors.navigation_2,
        borderRadius: '10px',
        position: 'relative',
      }}
      onCancel={ev => {
        props.onCancel();
      }}
      closable={false}
      footer={null}
      width={500}
      destroyOnClose={true}
      afterClose={() => {
        resetState();
      }}
    >
      <div
        className="edit-modal__close"
        onClick={() => {
          props.onCancel();
        }}
      >
        <Cross color={'#323232'} className="edit-modal__close__icon" />
      </div>
      <div
        className="edit-modal__title"
        style={{
          color: props.colors.navigation_1,
        }}
      >
        {t('profile.edit-modal__title')}
      </div>
      <div className="edit-modal__phone-container">
        <div className="edit-modal__phone-container__input-container">
          <div
            className="edit-modal__phone-container__input-container__title"
            style={{
              color: props.colors.navigation_1,
            }}
          >
            {t(
              'profile.edit_modal__phone_container__input_container__title.phone_number',
            )}
          </div>
          <InputMask
            className="edit-modal__phone-container__input-container__input"
            style={{
              borderColor: props.colors.primary,
              color: props.colors.navigation_1,
              backgroundColor: props.colors.navigation_2,
            }}
            type="tel"
            name="phoneNumber"
            placeholder="+7"
            mask="+7(999)999-99-99"
            onChange={ev => {
              const value = ev.target.value
                .replace(/\+7/, '7')
                .replace(/[()_-]/g, '');

              if (value.length === 11) {
                setPhoneNumber(value);
              } else if (phoneNumber) {
                setPhoneNumber(undefined);
                setOperationId(undefined);
                setVerifiedPhone(undefined);
              }
            }}
            maskChar="_"
            defaultValue={props.profile.phone}
          />
          <div
            className="edit-modal__phone-container__input-container__send-button"
            style={{
              color: props.colors.primary,
            }}
            onClick={() => {
              if (phoneNumber) {
                sendCode();
              } else {
                message.warning(
                  t(
                    'profile.edit_modal__phone_container__input_container__send_button.enter_new_phone_number',
                  ),
                );
              }
            }}
          >
            {t(
              'profile.edit_modal__phone_container__input_container__send_button.confirm_number',
            )}
          </div>
        </div>
        <div className="edit-modal__phone-container__input-container">
          <div
            className="edit-modal__phone-container__input-container__title"
            style={{
              opacity: operationId ? 1 : 0.5,
              color: props.colors.navigation_1,
            }}
          >
            {t(
              'profile.edit_modal__phone_container__input_container__title.sms_code',
            )}
          </div>
          <input
            className="edit-modal__phone-container__input-container__input"
            type="number"
            disabled={!operationId}
            style={{
              borderColor: props.colors.primary,
              color: props.colors.navigation_1,
              backgroundColor: props.colors.navigation_2,
            }}
            placeholder={t(
              'profile.edit-modal__phone-container__input-container__input',
            )}
            onChange={ev => {
              const value = ev.target.value;
              if (value.length === 6) {
                verifyCode(value);
              }
            }}
          />
        </div>
      </div>
      <div className="edit-modal__input-container">
        <div
          className="edit-modal__input-container__title"
          style={{
            color: props.colors.navigation_1,
          }}
        >
          {t('profile.edit_modal__input_container__title.name')}
        </div>
        <input
          className="edit-modal__input-container__input"
          onBlur={ev => {
            setName(ev.target.value);
          }}
          placeholder={t(
            'profile.edit_modal__input_container__input.enter_name',
          )}
          defaultValue={props.profile.name}
          style={{
            borderColor: props.colors.primary,
            color: props.colors.navigation_1,
            backgroundColor: props.colors.navigation_2,
          }}
        />
      </div>
      <div className="edit-modal__input-container">
        <div
          className="edit-modal__input-container__title"
          style={{
            color: props.colors.navigation_1,
          }}
        >
          {t('profile.edit_modal__input_container__title.surname')}
        </div>
        <input
          className="edit-modal__input-container__input"
          onBlur={ev => {
            setSurname(ev.target.value);
          }}
          placeholder={t(
            'profile.edit_modal__input_container__input.enter_surname',
          )}
          defaultValue={props.profile.surname}
          style={{
            borderColor: props.colors.primary,
            color: props.colors.navigation_1,
            backgroundColor: props.colors.navigation_2,
          }}
        />
      </div>
      <div className="edit-modal__input-container">
        <div
          className="edit-modal__input-container__title"
          style={{
            color: props.colors.navigation_1,
          }}
        >
          {t('profile.edit_modal__input_container__title.email')}
        </div>
        <input
          className="edit-modal__input-container__input"
          type="email"
          onBlur={ev => {
            if (includes('@', ev.target.value)) {
              setEmail(ev.target.value);
            }
          }}
          placeholder={t(
            'profile.edit_modal__input_container__input.enter_email',
          )}
          defaultValue={props.profile.email}
          style={{
            borderColor: props.colors.primary,
            color: props.colors.navigation_1,
            backgroundColor: props.colors.navigation_2,
          }}
        />
      </div>
      <div
        className="edit-modal__save-button"
        style={{
          backgroundColor: props.colors.primary,
          color: props.colors.navigation_2,
        }}
        onClick={() => {
          if (isEdited()) {
            setLoading(true);
            updateProfile();
          } else {
            props.onCancel();
          }
        }}
      >
        {isLoading ? (
          <Icon type="loading" />
        ) : (
          t('profile.edit-modal__save-button')
        )}
      </div>
    </Modal>
  );
};

export default EditModalComponent;
