import React from 'react';
import Cross from './Icons/Cross';
import { Modal } from 'antd';

import './index.scss';
import { useTranslation } from 'react-i18next';

function capitalizeFirstLetter(string) {
  if (string === null) return;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const FancoinsModalComponent = props => {
  const { t } = useTranslation();
  const isMobile = window.screen.width <= 990;
  let coins = props.coins;
  let pluralForms = props.loyaltyRules.loyaltyCurrencyName;
  let regularForm, bonusPluralForm;
  const isNull = pluralForms.plural === null;
  if (!isNull) {
    regularForm = capitalizeFirstLetter(pluralForms.plural).toLowerCase();
    bonusPluralForm = pluralForms.mod50;
  }
  let lastDigit = coins % 10;
  if (lastDigit === 1) {
    bonusPluralForm = pluralForms.mod1;
  }
  if (lastDigit > 1 && lastDigit <= 4) {
    bonusPluralForm = pluralForms.mod24;
  }
  let documentLink = props.loyaltyRules.legalityDocumentation
    ? props.loyaltyRules.legalityDocumentation.publicLink
    : null;
  let activePeriodText = ` ${t('profile.active_period_text.not_limited')}`;
  let activePeriodDays = props.loyaltyRules.bonusActivePeriodDays;

  if (activePeriodDays) {
    activePeriodText = ` ${activePeriodDays} ${t(
      'profile.active_period_text.days',
    )}`;
  }

  return (
    <>
      <Modal
        open={props.visible}
        width={764}
        className={'fancoinsModal'}
        centered={isMobile}
        onCancel={() => {
          props.onCancel();
        }}
        bodyStyle={{
          backgroundColor: '#fff',
          borderRadius: '10px',
          padding: '0',
        }}
        closable={false}
        footer={null}
      >
        <>
          <div className={'fancoinsModal__header'}>
            <div className="fancoinsModal__header__title">
              {t('profile.fancoinsModal__header__title')}
            </div>
            <div className="fancoinsModal__header__description">
              {t('profile.fancoins_modal__header__description.0')} {regularForm}
              {', '}
              {t('profile.fancoins_modal__header__description.1')} {'.'}
            </div>
            <div className="fancoinsModal__header__info-title">
              {t('profile.fancoins_modal__header__info-title')}
            </div>
            <div className="fancoinsModal__header__count-container">
              <div className="fancoinsModal__header__count-container__number">
                {props.coins}
              </div>
              &nbsp;
              <div className="fancoinsModal__header__count-container__title">
                {!isNull && bonusPluralForm.toUpperCase()}
              </div>
            </div>
            <div
              className={'fancoinsModal__header__close'}
              onClick={() => {
                props.onCancel();
              }}
            >
              <Cross className={'fancoinsModal__header__close__icon'} />
            </div>
          </div>
          <div className={'fancoinsModal__main'}>
            <div className="fancoinsModal__main__title">
              {t('profile.fancoins_modal__main__title.more_fun')}{' '}
              {!isNull && pluralForms.mod50.toLowerCase()}!
            </div>
            <div className="fancoinsModal__main__text">
              {t('profile.fancoins_modal__main__text.div_1.text_1')}{' '}
              {!isNull && pluralForms.plural.toLowerCase()}{' '}
              {t('profile.fancoins_modal__main__text.div_1.text_2')}{' '}
              {parseInt(props.loyaltyRules.accrualPercentage)}%{' '}
              {t('profile.fancoins_modal__main__text.div_1.text_3')}{' '}
              {!isNull && pluralForms.plural}{' '}
              {t('profile.fancoins_modal__main__text.div_1.text_4')}{' '}
              {!isNull && pluralForms.mod50.toLowerCase()} {activePeriodText}.
            </div>
            <div className="fancoinsModal__main__title">
              {t('profile.fancoins_modal__main__title.participation_rules')}
            </div>
            <div className="fancoinsModal__main__text">
              {t('profile.fancoins_modal__main__text.div_2.text_1')}{' '}
              {!isNull && pluralForms.plural.toLowerCase()}{' '}
              {t('profile.fancoins_modal__main__text.div_2.text_2')}{' '}
              {parseInt(props.loyaltyRules.bonusPriceCoverPercentage)}%{' '}
              {t('profile.fancoins_modal__main__text.div_2.text_3')}{' '}
              {props.loyaltyRules.applicableWithDiscount ? (
                <>{t('profile.fancoins_modal__main__text.div_2.text_4')}</>
              ) : (
                <>{t('profile.fancoins_modal__main__text.div_2.text_5')}</>
              )}{' '}
              {t('profile.fancoins_modal__main__text.div_2.text_6')}
              <br />
              {documentLink && (
                <>
                  {' '}
                  {t('profile.fancoins_modal__main__text.div_2.text_7')}{' '}
                  <a href={documentLink}>
                    {t('profile.fancoins_modal__main__text.div_2.text_8')}
                  </a>
                  .
                </>
              )}
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default FancoinsModalComponent;
