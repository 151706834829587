import 'antd/dist/reset.css';
import './assets/less/index.less';
import './assets/scss/index.scss';
import * as Sentry from '@sentry/react';

import { Application } from './modules/app/src';
import { ModuleAccount } from './modules/account/src';
import { AuthModule } from './modules/auth/src/auth';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';
import CrossDomainCredentialStorage from '@sebbia/auth-client/lib/CrossDomainCredentialStorage';
import GraphQLClientImpl from '@sebbia/auth-client/lib/GraphQLClientImpl';
import { createTokenRefreshLink } from '@sebbia/auth-client/lib/CredentailsRefreshLink';
import { pathOr } from 'ramda';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

import less from 'less';
import './utils/windowGlobals';

import './i18n';
import { t } from 'i18next';
import { getSupportedLngs } from './i18n';

import './figma/styles/colors.css';
import './figma/styles/text.css';

const currentLngs = getSupportedLngs();

const colorOverridesStyle = document.createElement('style');
colorOverridesStyle.innerHTML = `
  :root {
    --fan-theme_clubBg1-override: ${window.parametrize('REACT_APP_CLUB_BG_1')};
    --fan-theme_clubBg2-override: ${window.parametrize('REACT_APP_CLUB_BG_2')};
    --fan-theme_clubBg3-override: ${window.parametrize('REACT_APP_CLUB_BG_3')};
    --fan-theme_clubNavigation1-override: ${window.parametrize(
      'REACT_APP_CLUB_NAVIGATION_1',
    )};
    --fan-theme_clubNavigation2-override: ${window.parametrize(
      'REACT_APP_CLUB_NAVIGATION_2',
    )};
    --fan-theme_clubPrimary-override: ${window.parametrize(
      'REACT_APP_CLUB_PRIMARY',
    )};
    --fan-theme_clubPrimaryLight-override: ${window.parametrize(
      'REACT_APP_CLUB_PRIMARY_LIGHT',
    )};
  }
`;
document.getElementsByTagName('head')[0].appendChild(colorOverridesStyle);

less.modifyVars({
  '@link-active-color': window.parametrize('REACT_APP_LINK_ACTIVE_COLOR'),
  '@link-color': window.parametrize('REACT_APP_LINK_COLOR'),
  '@primary-color': window.parametrize('REACT_APP_PRIMARY_COLOR') || 'tomato',
  '@input-border-color': window.parametrize('REACT_APP_INPUT_BORDER_COLOR'),
  '@input-bg': window.parametrize('REACT_APP_INPUT_BG'),
  '@btn-font-weight': window.parametrize('REACT_APP_FONT_WEIGHT'),
  '@btn-default-bg': window.parametrize('REACT_APP_DEFAULT_BG'),
});

document.title = `${t('react_app.title')}`;

const metaTitle = document.createElement('meta');
metaTitle.setAttribute('name', 'title');
metaTitle.content = t('react_app.meta_title');
const metaDescr = document.createElement('meta');
metaDescr.setAttribute('name', 'description');
metaDescr.content = t('react_app.meta_description');

document.getElementsByTagName('head')[0].appendChild(metaTitle);
document.getElementsByTagName('head')[0].appendChild(metaDescr);

(<any>document).querySelector(
  "link[rel='shortcut icon']",
).href = window.parametrize('REACT_APP_FAVICON');

Sentry.init({
  release: process.env['REACT_APP_VERSION_TAG'],
  dsn: window.parametrize('REACT_APP_SENTRY_DNS'),
  environment: window.parametrize('REACT_APP_SENTRY_ENV'),
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

// Yandex metrica
const yandexMetricaId = window.parametrize('REACT_APP_METRICA_ID');

if (yandexMetricaId !== '') {
  const yandexScript = document.createElement('script');
  const yandexNoScript = document.createElement('noscript');
  const yandexNoScriptDiv = document.createElement('div');
  const yandexNoScriptImg = document.createElement('img');
  yandexNoScriptImg.src = `https://mc.yandex.ru/watch/${yandexMetricaId}`;
  yandexNoScriptImg.style.position = 'absolute';
  yandexNoScriptImg.style.left = '-9999px';
  yandexNoScriptDiv.appendChild(yandexNoScriptImg);
  yandexNoScript.appendChild(yandexNoScriptDiv);

  yandexScript.innerHTML = `
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    window.ym = ym;

    ym(${yandexMetricaId}, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
    });
  `;

  document.head.appendChild(yandexScript);
  document.head.appendChild(yandexNoScript);
}

// Yandex metrica

// Facebook pixel
const facebookPixelId = window.parametrize('REACT_APP_FACEBOOK_PIXEL_ID');
if (facebookPixelId !== '') {
  const facebookScript = document.createElement('script');
  facebookScript.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', ${facebookPixelId});
    fbq('track', 'PageView');
  `;

  const facebookNoScript = document.createElement('noscript');
  const facebookNoScriptDiv = document.createElement('div');
  const facebookNoScriptImg = document.createElement('img');
  facebookNoScriptImg.src = `https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1`;
  facebookNoScriptImg.style.height = '1';
  facebookNoScriptImg.style.width = '1';
  facebookNoScriptImg.style.display = 'none';
  facebookNoScriptDiv.appendChild(facebookNoScriptImg);
  facebookNoScript.appendChild(facebookNoScriptDiv);

  document.head.appendChild(facebookScript);
  document.body.appendChild(facebookNoScript);
}

// Facebook pixel

const startApp = async () => {
  const graphQlClient = new GraphQLClientImpl(
    window.parametrize('REACT_APP_GRAPHQL_ENDPOINT'),
    20000,
  );
  const storage = new CrossDomainCredentialStorage(
    window.parametrize('REACT_APP_CROSSDOMAIN_STORAGE'),
    'SportId',
    20000,
  );
  const authClient = new AuthClient(graphQlClient, storage);
  try {
    await authClient.init();
  } catch (e) {
    console.error(e);
  }
  const refreshAuthLink = createTokenRefreshLink(authClient);
  const app = new Application();
  const authModule = new AuthModule(authClient, app);
  const moduleAccount = new ModuleAccount(app, authClient);

  const iframe = document.getElementsByName('authClientIframe')[0];

  iframe.style.cssText =
    'width: 0; height: 0; position: absolute; top: -9000px';

  if (authClient.isAuthorized()) {
    const credentials = authClient.credentials
      ? authClient.credentials
      : { idToken: '' };
    const idToken = credentials.idToken;
    try {
      const res = await fetch(
        window.parametrize('REACT_APP_GRAPHQL_ENDPOINT'),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: 'Bearer ' + idToken,
            'Accept-Languages': currentLngs.join(','),
          },
          body: JSON.stringify({
            query: `
            query getProfile{
              users{
                getProfile{
                  id
                }
              }
            }
          `,
            operationName: null,
          }),
        },
      );
      const data = await res.json();
      const id = pathOr('', ['data', 'users', 'getProfile', 'id'], data);
      Sentry.configureScope(function(scope) {
        scope.setUser({
          id: id,
        });
      });
    } catch (e) {
      console.error(e);
    }
  }

  app.start(authClient, refreshAuthLink);
  [moduleAccount, authModule].forEach(element => {
    element.start();
  });
};
startApp();
