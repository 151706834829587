import React from 'react';
import { Typography, Input } from 'antd';

const { Text } = Typography;

const PersonalInfoField = props => {
  const FieldComponent = props.component;
  return (
    <Typography className="df jcfs aic personal-info__field">
      <Text type="secondary">{props.label}</Text>
      {props.component ? (
        <FieldComponent
          value={props.value}
          onChange={e => props.setFieldValue(props.name, e._d)}
          name={props.name}
          {...props.componentProps}
        />
      ) : (
        <Input
          className="input"
          value={props.value}
          onChange={e => props.setFieldValue(props.name, e.target.value)}
          name={props.name}
        />
      )}
    </Typography>
  );
};

export default PersonalInfoField;
