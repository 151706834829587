import gql from 'graphql-tag';

export const GET_VISIBLE_INFO = gql`
  query getVisibleInfo($id: ID!) {
    seasonTicket {
      getByVisibleId(visibleId: $id) {
        visibleId
        user {
          id
        }
        place {
          number
          row {
            number
            sector {
              title
            }
          }
        }
      }
    }
  }
`;
