import React, { useState } from 'react';
import './index.scss';
import { pathOr, contains } from 'ramda';
import { Dropdown, Menu, message } from 'antd';
import DestructiveConfirmation from 'common/Components/Confirmation/DestructiveConfirmation';
import {
  GET_TICKET_PDF,
  REFUND_TICKET,
  SEND_ORDER_TO_PHONE,
  GET_SEASON_TICKET_PDF,
  GET_SEASON_TICKET_PKPASS,
  GET_TICKET_PKPASS,
} from '../../../graphql';
import { useTranslation } from 'react-i18next';
import { formatShortSectorName } from 'utils/helpers';

const PlaceComponent = props => {
  const { t } = useTranslation();
  const [isCanceled, setCanceled] = useState(
    props.isTicket ? props.ticket.status === 'CANCELED' : false,
  );
  const [refundModalVisible, setRefundModalVisible] = useState(false);
  const place = pathOr({}, ['place'], props.ticket);

  const sector = pathOr('', ['row', 'sector', 'title'], place);
  const textSector = formatShortSectorName(sector);

  const refundUntil = pathOr({}, ['orderItem', 'refundUntil'], props.ticket);
  const refundUntilDate = new Date(refundUntil);
  const nowDate = new Date();
  const isRefundDateExpired = nowDate.getTime() < refundUntilDate.getTime();

  function download_file(linkUrl) {
    let fileName = linkUrl.split('/').reverse()[0];
    let link = document.createElement('a');
    link.href = linkUrl + '?downloadable';
    link.download = fileName || 'unknown';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const getTicketPdf = async () => {
    try {
      message.loading(t('inventory.get_ticket_pdf.message_loading'));
      const res = await props.client.query({
        query: props.isTicket ? GET_TICKET_PDF : GET_SEASON_TICKET_PDF,
        fetchPolicy: 'no-cache',
        variables: {
          id: props.ticket.id,
        },
      });
      const link = pathOr(
        '',
        [
          'data',
          props.isTicket ? 'ticket' : 'seasonTicket',
          'getPdfById',
          'publicLink',
        ],
        res,
      );
      message.success(t('inventory.get_ticket_pdf.message_success'));
      download_file(link);
    } catch (e) {
      console.error(e);
      message.error(t('inventory.get_ticket_pdf.message_error'));
    }
  };

  const sendOrderToPhone = async () => {
    try {
      await props.client.query({
        query: SEND_ORDER_TO_PHONE,
        fetchPolicy: 'no-cache',
        variables: {
          orderId: props.ticket.order.id,
          phone: props.userPhone,
        },
      });
      message.success(t('inventory.send_order_to_phone.message_success'));
    } catch (e) {
      console.error(e);
      message.error(t('inventory.send_order_to_phone.message_error'));
    }
  };

  const getWalletLink = async () => {
    try {
      message.loading(t('inventory.get_wallet_link.message_loading'));
      const res = await props.client.query({
        query: props.isTicket ? GET_TICKET_PKPASS : GET_SEASON_TICKET_PKPASS,
        fetchPolicy: 'no-cache',
        variables: {
          id: props.ticket.id,
        },
      });
      const link = pathOr(
        '',
        [
          'data',
          props.isTicket ? 'ticket' : 'seasonTicket',
          'getPkpassById',
          'publicLink',
        ],
        res,
      );
      message.success(t('inventory.get_wallet_link.message_success'));
      window.open(link);
    } catch (e) {
      console.error(e);
      message.error(t('inventory.get_wallet_link.message_error'));
    }
  };

  const refundTicket = async () => {
    try {
      setRefundModalVisible(false);
      message.loading(t('inventory.refund_ticket.message_loading'));
      await props.client.query({
        query: REFUND_TICKET,
        fetchPolicy: 'no-cache',
        variables: {
          orderId: props.ticket.order.id,
          orderItemIds: [props.ticket.orderItem.id],
        },
      });
      message.success(t('inventory.refund_ticket.message_success'));
      setCanceled(true);
    } catch (e) {
      console.error(e);
      message.error(t('inventory.refund_ticket.message_error'));
    }
  };

  return (
    <>
      <DestructiveConfirmation
        visible={refundModalVisible}
        title={t('inventory.destructive_confirmation.title')}
        text={t('inventory.destructive_confirmation.text')}
        onAccept={refundTicket}
        onCancel={() => {
          setRefundModalVisible(false);
        }}
        acceptButtonTitle={t(
          'inventory.destructive_confirmation.accept_button_title',
        )}
        cancelButtonTitle={t(
          'inventory.destructive_confirmation.cancel_button_title',
        )}
      />
      {!isCanceled ? (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu selectable={false}>
              <Menu.Item
                key="1"
                onClick={() => {
                  getTicketPdf();
                }}
              >
                <span>{t('inventory.menu_item.span.download_pdf')}</span>
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() => {
                  sendOrderToPhone();
                }}
              >
                <span>{t('inventory.menu_item.span.send_number_SMS')}</span>
              </Menu.Item>
              {props.walletPassAvailable && (
                <Menu.Item
                  key="3"
                  onClick={() => {
                    getWalletLink();
                  }}
                >
                  <span>{t('inventory.menu_item.span.add_wallet')}</span>
                </Menu.Item>
              )}
              {props.isTicket &&
              isRefundDateExpired &&
              window.parametrize('REACT_APP_ALLOW_REFUND') === 'true' ? (
                <Menu.Item
                  key={props.walletPassAvailable ? '4' : '3'}
                  onClick={() => {
                    setRefundModalVisible(true);
                  }}
                >
                  <span style={{ color: 'var(--danger)' }}>
                    {t('inventory.menu_item.span.return_ticket')}
                  </span>
                </Menu.Item>
              ) : (
                ''
              )}
            </Menu>
          }
        >
          <div className="place">
            <div className="place__inner-container">
              <div className="place__inner-container__title-container">
                <div className="place__inner-container__title-container__title">
                  {t(
                    'inventory.place__inner_container__title_container__title.sector',
                  )}
                </div>
                <div className="place__inner-container__title-container__title">
                  sector
                </div>
              </div>
              <div
                className="place__inner-container__data"
                style={{
                  width: '63px',
                }}
              >
                {textSector}
              </div>
            </div>

            <div className="place__inner-container">
              <div className="place__inner-container__title-container">
                <div className="place__inner-container__title-container__title">
                  {t(
                    'inventory.place__inner_container__title_container__title.row',
                  )}
                </div>
                <div className="place__inner-container__title-container__title">
                  row
                </div>
              </div>
              <div className="place__inner-container__data">
                {pathOr('', ['row', 'number'], place).length < 2
                  ? '0' + pathOr('', ['row', 'number'], place)
                  : pathOr('', ['row', 'number'], place)}
              </div>
            </div>

            <div className="place__inner-container">
              <div className="place__inner-container__title-container">
                <div className="place__inner-container__title-container__title">
                  {t(
                    'inventory.place__inner_container__title_container__title.place',
                  )}
                </div>
                <div className="place__inner-container__title-container__title">
                  seat
                </div>
              </div>
              <div
                className="place__inner-container__data-green"
                style={{
                  color: props.colors.primary,
                }}
              >
                {pathOr('', ['number'], place).length < 2
                  ? '0' + pathOr('', ['number'], place)
                  : pathOr('', ['number'], place)}
              </div>
            </div>
            <div className="place__menu-button" />
          </div>
        </Dropdown>
      ) : (
        <div className="place__canceled">{t('inventory.place__canceled')}</div>
      )}
    </>
  );
};

export default PlaceComponent;
