import LoginForm from './Auth/components/index';
import { IRouteContainer } from '../../../common/src';
import { withProps } from 'recompose';
import './Auth/style.scss';
import { includes } from 'ramda';

import AuthClient from '@sebbia/auth-client/lib/AuthClient';

export class AuthModule {
  private routeContainer: IRouteContainer;
  public authClient: AuthClient;
  component: any; //TODO

  constructor(authClient: AuthClient, routeContainer: IRouteContainer) {
    this.authClient = authClient;

    this.component = {
      auth: withProps({
        authClient: this.authClient,
      })(LoginForm),
    };
    this.routeContainer = routeContainer;
    this.routeContainer.addRoute({
      path: '/login',
      component: this.component.auth,
      protectedRoute: false,
    });
  }
  start() {
    //TODO implemented via useHistory
    if (
      !this.authClient.credentials &&
      !includes('/login', window.location.pathname)
    ) {
      window.location.href = window.location.origin + '/login';
    } else if (
      this.authClient.credentials &&
      !includes('/account/subscriptions', window.location.pathname)
    ) {
      window.location.href = window.location.origin + '/account/subscriptions';
    }
  }
}
