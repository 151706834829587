import React from 'react';

const Cross = props => {
  return (
    <svg
      className={props.className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.07238 13.0941C1.441 13.0941 0.919434 12.5726 0.919434 11.9275C0.919434 11.6118 1.04296 11.3373 1.26257 11.1314L5.36649 7.01374L1.26257 2.89609C1.04296 2.70394 0.919434 2.4157 0.919434 2.10002C0.919434 1.46864 1.441 0.9608 2.07238 0.9608C2.40179 0.9608 2.66257 1.08433 2.86845 1.30394L6.9861 5.40786L11.1312 1.29021C11.3645 1.04315 11.6253 0.93335 11.9273 0.93335C12.5724 0.93335 13.0802 1.45492 13.0802 2.08629C13.0802 2.40198 12.9841 2.66276 12.7371 2.88237L8.61944 7.01374L12.7234 11.1039C12.943 11.3235 13.0665 11.5981 13.0665 11.9275C13.0665 12.5726 12.5587 13.0941 11.9136 13.0941C11.5841 13.0941 11.2959 12.9569 11.09 12.751L6.9861 8.63335L2.8959 12.751C2.69002 12.9706 2.40179 13.0941 2.07238 13.0941Z"
        fill="#323232"
      />
    </svg>
  );
};

export default Cross;
