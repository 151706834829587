import gql from 'graphql-tag';

export const UPDATE_USER = gql`
  mutation updateUser($user: ProfileUpdateInput!) {
    users {
      updateProfile(user: $user) {
        id
        person {
          contacts {
            type
            value
          }
        }
      }
    }
  }
`;

export const ADD_SUBSCRIPTION_TO_NUMBER = gql`
  mutation addSubscriptionToNumber($seasonTicketIds: [ID!]!) {
    seasonTicket {
      attachToUser(seasonTicketIds: $seasonTicketIds) {
        id
        descriptor {
          id
        }
      }
    }
  }
`;

export const PROLONG_SUBSCRIPTION = gql`
  mutation prolongSubscription($data: ProlongSeasonTicketInput!) {
    seasonTicket {
      prolong(data: $data) {
        id
      }
    }
  }
`;

export const SEND_CONFIRMATION_EMAIL = gql`
  mutation confirmEmail($email: String!, $confirmationResultUrl: String!) {
    contacts {
      sendEmailConfirmationCode(
        email: $email
        confirmationResultUrl: $confirmationResultUrl
      ) {
        operationId
        nextTryAfterMsecs
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateUser(
    $user: ProfileUpdateInput!
    $email: String!
    $confirmationResultUrl: String!
  ) {
    users {
      updateProfile(user: $user) {
        id
        person {
          contacts {
            type
            value
          }
        }
      }
    }
    contacts {
      sendEmailConfirmationCode(
        email: $email
        confirmationResultUrl: $confirmationResultUrl
      ) {
        operationId
        nextTryAfterMsecs
      }
    }
  }
`;
