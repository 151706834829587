import gql from 'graphql-tag';

export const UPDATE_USER = gql`
  mutation($user: ProfileUpdateInput!) {
    users {
      updateProfile(user: $user) {
        id
      }
    }
  }
`;

export const SEND_CONFIRM_CODE = gql`
  mutation($phoneNumber: String!) {
    contacts {
      sendPhoneConfirmationCode(phoneNumber: $phoneNumber) {
        operationId
      }
    }
  }
`;

export const CONFIRM_CODE = gql`
  mutation($operationId: ID!, $code: String!) {
    contacts {
      confirmWithCode(operationId: $operationId, code: $code)
    }
  }
`;

export const GET_TICKET_PDF = gql`
  query($id: ID!) {
    ticket {
      getPdfById(id: $id) {
        publicLink
      }
    }
  }
`;
export const GET_SEASON_TICKET_PDF = gql`
  query($id: ID!) {
    seasonTicket {
      getPdfById(id: $id) {
        publicLink
      }
    }
  }
`;
export const REFUND_TICKET = gql`
  mutation($orderId: ID!, $orderItemIds: [ID!]!) {
    payments {
      partialRefund(orderId: $orderId, orderItemIds: $orderItemIds) {
        id
      }
    }
  }
`;
export const SEND_ORDER_TO_PHONE = gql`
  mutation($orderId: ID!, $phone: String!) {
    order {
      sendToCustomPhone(orderId: $orderId, phone: $phone)
    }
  }
`;
