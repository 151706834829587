import { t } from 'i18next';
import { includes } from 'ramda';

export const getErrorMessage = err => {
  if (includes('already registered', err.message)) {
    return t('app_store.get_error_message');
  }
  const errorCodes = [
    { code: 'INTERNAL_SERVER_EROR', message: t('app_store.error_codes') },
  ];
  const message = (errorCodes.find(item => item.code === err.code) || {})
    .message;

  return message ? message : err.message;
};
