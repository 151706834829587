import CyrillicToTranslit from 'cyrillic-to-translit-js';

const cyrillicToTranslit = new CyrillicToTranslit();

export const deleteLocalData = name => {
  window.localStorage.removeItem(name);
};

export const getLocalData = (name, defaultValue) => {
  try {
    const data = JSON.parse(window.localStorage.getItem(name));
    return data ? data : defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const setLocalData = (name, value) => {
  window.localStorage.setItem(name, JSON.stringify(value));
};

export const transliterate = string => {
  const strTrans = cyrillicToTranslit.transform(string);
  const res = strTrans.charAt(0).toUpperCase() + strTrans.slice(1);

  if (strTrans.length === 0) {
    return strTrans;
  } else {
    return res;
  }
};

export const phoneRegExp = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

export function isPhoneNumber(phone) {
  const reg = new RegExp(/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/);
  return reg.test(phone);
}

export function isEmailValid(email) {
  const reg = new RegExp(
    '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|' +
      '(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+' +
      '[a-zA-Z]{2,}))$',
  );
  return reg.test(email);
}

export function normalizeNumber(number) {
  try {
    const numberStr = String(number);
    const numArray = numberStr.split('.');
    if (numArray[1] === '00') return numArray[0];
    return number;
  } catch {
    return number;
  }
}

export function formatShortSectorName(sectorName) {
  if (sectorName.length > 3) {
    return sectorName
      .split(' ')
      .map(it => it[0])
      .join('');
  } else {
    return sectorName;
  }
}
