import React from 'react';
import { Empty } from 'antd';
import { Query } from 'react-apollo';
import { GET_PROFILE_INFO } from '../../graphql';
import CustomQuery from '../../../../../../common/CustomQuery';
import { isEmpty, pathOr, __, propOr, compose, find } from 'ramda';
import PersonalInfo from './PersonalInfo';
import { useTranslation } from 'react-i18next';

const InfoComponent = props => {
  const { t } = useTranslation();
  return (
    <div>
      <Query query={GET_PROFILE_INFO}>
        {res => {
          const { data, loading, error } = res;
          if (isEmpty(data)) {
            return <CustomQuery request={{ loading, error }} />;
          }
          const person = pathOr(false, ['users', 'getProfile', 'person'], data);
          const findFromContacts = (type, field) => {
            return compose(
              propOr('', field),
              find(item => item.type === type),
              propOr([], 'contacts'),
            )(person);
          };
          const pathOfNull = pathOr('', __, person);
          if (person) {
            const mappedPerson = {
              name: pathOfNull(['name', 'en']),
              surname: pathOfNull(['surname', 'en']),
              patronymic: pathOfNull(['patronymic', 'en']),
              birthday: pathOfNull(['birthday']),
              email: findFromContacts('EMAIL', 'value'),
              phone: findFromContacts('PHONE', 'value'),
              isPhoneConfirmed: findFromContacts('PHONE', 'isConfirmed'),
              isEmailConfirmed: findFromContacts('EMAIL', 'isConfirmed'),
            };
            return (
              <PersonalInfo
                mappedPerson={mappedPerson}
                updateProfile={props.updateProfile}
              />
            );
          } else {
            return (
              <Empty description={t('account_panel.info_component.empty')} />
            );
          }
        }}
      </Query>
    </div>
  );
};

export default InfoComponent;
