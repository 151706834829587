export default () => {
  var style = document.createElement('style');
  style.type = 'text/css';
  const h = `
		.ant-btn {
			font-weight: 550;
			font-size: 14px;
		}
        .ant-btn:hover { background-color: ${window.parametrize(
          'REACT_APP_PRIMARY_COLOR',
        )} }
		.ant-btn:focus { background-color: ${window.parametrize(
      'REACT_APP_PRIMARY_COLOR',
    )};
		  color: #fff; }
        ._reset:hover { background-color: transparent; }
        .subscription__item {
          background: ${window.parametrize('REACT_APP_SECONDARY_COLOR')}
		}
		.person-info__btn-exit {
			background-color: #fff;
			color: ${window.parametrize('REACT_APP_PRIMARY_COLOR')};
			border-color: ${window.parametrize('REACT_APP_PRIMARY_COLOR')};
			box-shadow: none;
		}
		.ant-menu-horizontal > .ant-menu-item:hover, 
		.ant-menu-horizontal > .ant-menu-item-selected {
			color: ${window.parametrize('REACT_APP_PRIMARY_COLOR')} !important;
			border-bottom: 3px solid ${window.parametrize(
        'REACT_APP_PRIMARY_COLOR',
      )} !important;
		}
		.subscription__dropdown, .purchases-tickets__dropdown {
			color: ${window.parametrize('REACT_APP_PRIMARY_COLOR')};
		}
		.subscription__dropdown:hover, .purchases-tickets__dropdown:hover,
		.subscription__dropdown:focus, .purchases-tickets__dropdown:focus {
			background-color: ${window.parametrize('REACT_APP_PRIMARY_COLOR')};
			color: #fff;
			border: none;
		}
		.ant-menu-item {
			color: #000;
		}
		.ant-menu-item:hover {
			color: ${window.parametrize('REACT_APP_PRIMARY_COLOR')};
		}
		.personal-info__btn {
			background: ${window.parametrize('REACT_APP_PRIMARY_COLOR')};
			border: none;
		}
		.personal-info__btn:hover {
			color: #fff;
		}
		
    `;
  const hover = document.createTextNode(h);
  let head = document.querySelector('head');
  style.appendChild(hover);
  head.appendChild(style);
};
