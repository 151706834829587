import React, { useState, useEffect } from 'react';
import { GET_LOYALTY_STATUS, GET_COINS, GET_LOYAL } from '../../graphql';
import { pathOr } from 'ramda';
import './index.scss';
import EditModalComponent from './EditModal/EditModalComponent';
import FancoinsModalComponent from './FancoinsModalComponent/FancoinsModalComponent';
import { normalizeNumber } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

const ProfileComponent = props => {
  const [profile, setProfile] = useState({});
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const { t } = useTranslation();
  const [isProfileCoinsModalVisible, setProfileCoinsModalVisible] = useState(
    false,
  );
  const [isLoaded, setLoaded] = useState(false);
  const [coins, setCoins] = useState(0);
  const [loyaltyActive, setLoyaltyActive] = useState(false);
  const [loyaltyRules, setLoyaltyRules] = useState({
    // Percentage of the cost that can be paid
    bonusPriceCoverPercentage: 100,

    // Percentage after payment
    accrualPercentage: 0,

    // Percentage of total points that can be used at a time
    maxExpenditurePerTransactionPercentage: 100,

    // Can be applied together with discount
    applicableWithDiscount: false,

    // Juristic documents
    legalityDocumentation: { publicLink: null },

    // Name of the bonus currency
    loyaltyCurrencyName: {
      plural: null,
      mod1: null,
      mod24: null,
      mod50: null,
    },

    // Is the program active
    isActive: false,
  });

  const [currencyLiteral, setCurrencyLiteral] = useState('');

  const fetchData = async () => {
    const res = await props.client.query({
      query: GET_LOYAL,
      fetchPolicy: 'no-cache',
    });
    const data = pathOr(
      [],
      [
        'data',
        'loyalty',
        'loyaltyProgramQuery',
        'getProgram',
        'loyaltyCurrencyName',
      ],
      res,
    );
    setLoyaltyRules({
      ...loyaltyRules,
      loyaltyCurrencyName: {
        plural: data.plural,
        mod1: data.mod1,
        mod24: data.mod24,
        mod50: data.mod50,
      },
    });
  };

  useEffect(() => {
    if (loyaltyRules.loyaltyCurrencyName.plural != null) {
      setCurrencyLiteral(
        loyaltyRules.loyaltyCurrencyName.plural[0].toUpperCase(),
      );
    }
  }, [loyaltyRules]);

  const getLoyaltyProgram = async () => {
    const loyaltyRes = await props.client.query({
      query: GET_LOYALTY_STATUS,
      fetchPolicy: 'no-cache',
    });

    const isLoyaltyActiveData = pathOr(
      false,
      [
        'data',
        'loyalty',
        'loyaltyDescriptorQuery',
        'getDescriptor',
        'isActive',
      ],
      loyaltyRes,
    );

    setLoyaltyActive(isLoyaltyActiveData);

    const loyaltyProgram = pathOr(
      false,
      ['data', 'loyalty', 'loyaltyDescriptorQuery', 'getDescriptor'],
      loyaltyRes,
    );

    setLoyaltyRules(loyaltyProgram);
    return isLoyaltyActiveData;
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (window.parametrize('REACT_APP_ENABLE_FANCOINS') === 'true') {
          const loyaltyStatus = await getLoyaltyProgram();

          if (loyaltyStatus) {
            const coinsRes = await props.client.query({
              query: GET_COINS,
              fetchPolicy: 'no-cache',
            });

            const coinsData = pathOr(
              0,
              ['data', 'loyalty', 'transactionQuery', 'getUserBonusCount'],
              coinsRes,
            );

            setCoins(coinsData);
          }
        }
        await fetchData();
      } catch (e) {
        console.error(e);
      }
      setLoaded(true);
    };

    init();
  }, []);

  useEffect(() => {
    setProfile(props.profile);
  }, [props.profile]);

  return (
    <>
      {isLoaded ? (
        <>
          <div className={props.desktop ? 'desktop' : 'aside'}>
            <div
              className={props.desktop ? 'profile desktop' : 'profile'}
              style={{
                backgroundColor: props.colors.navigation_2,
              }}
            >
              <div
                className="profile__username"
                style={{
                  color: props.colors.navigation_1,
                }}
              >
                {profile.name}
                <br />
                {profile.surname}
              </div>
              {loyaltyActive ? (
                <div className="profile__fancoins">
                  <div
                    className="profile__fancoins__title"
                    style={{
                      color: props.colors.navigation_1,
                    }}
                  >
                    {loyaltyRules.loyaltyCurrencyName.plural}
                  </div>
                  <div
                    className={'profile__fancoins__icon'}
                    onClick={() => {
                      setProfileCoinsModalVisible(true);
                    }}
                    style={{
                      color: props.colors.navigation_1,
                      backgroundColor:
                        window.parametrize('REACT_APP_PLACEHOLDER') === 'LOKO'
                          ? 'rgba(0,0,0,0.2)'
                          : 'rgba(255,255,255,0.2)',
                    }}
                  >
                    ?
                  </div>
                  <div className="profile__fancoins__count">
                    {normalizeNumber(coins) + ' ' + currencyLiteral}
                  </div>
                </div>
              ) : (
                ''
              )}
              <div
                style={{
                  color: props.colors.navigation_1,
                }}
                className="profile__subtitle"
              >
                {t('profile.profile__subtitle.mobile_phone')}
              </div>
              <div
                style={{
                  color: props.colors.navigation_1,
                }}
                className="profile__value"
              >
                {profile.phone}
              </div>
              <div
                style={{
                  color: props.colors.navigation_1,
                }}
                className="profile__subtitle"
              >
                {t('profile.profile__subtitle.email')}
              </div>
              <div
                style={{
                  color: props.colors.navigation_1,
                }}
                className="profile__value"
              >
                {profile.email}
              </div>
              <div
                className="profile__button"
                onClick={ev => {
                  setEditModalOpen(true);
                }}
                style={{
                  color: props.colors.primary,
                }}
              >
                {t('profile.profile__button.edit')}
              </div>
              <div
                className="profile__button"
                style={{
                  color: props.colors.primary,
                }}
                onClick={() => {
                  props.authClient.logout();
                  window.location.reload();
                }}
              >
                {t('profile.profile__button.exit')}
              </div>
              <EditModalComponent
                client={props.client}
                setName={props.setName}
                visible={isEditModalOpen}
                onCancel={() => {
                  setEditModalOpen(false);
                }}
                profile={profile}
                setProfile={profile => {
                  setProfile(profile);
                }}
                colors={props.colors}
              />
              {loyaltyActive ? (
                <FancoinsModalComponent
                  key="<4ed08da2>"
                  visible={isProfileCoinsModalVisible}
                  coins={parseInt(coins)}
                  loyaltyRules={loyaltyRules}
                  onCancel={() => {
                    setProfileCoinsModalVisible(false);
                  }}
                />
              ) : (
                ''
              )}
            </div>

            <div className="aside__assembly">
              {t('profile.aside__assembly')}:{' '}
              {process.env['REACT_APP_VERSION_TAG']}
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};
export default ProfileComponent;
