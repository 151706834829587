import Account from './Account/index';
import Info from './AccountPanel/Info';
import {
  ISidebar,
  IApp,
  Action,
  Module,
  ModuleRoute,
  IRouteContainer,
} from '../../common/src';
import { addMenuItem } from './AccountPanel/actions';
import { withProps } from 'recompose';
import reducer from './Account/reducer';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';

export class ModuleAccount implements ISidebar, Module, IRouteContainer {
  private app: IApp;
  private component: any;
  routes: ModuleRoute[] = [];
  links: Action[];
  reducer: any;

  addLink(action: Action) {
    this.links.push(action);
  }

  addRoutes(routes: ModuleRoute[]) {
    routes.map(route => this.routes.push(route));
  }

  addRoute(route: ModuleRoute) {
    this.routes.push(route);
  }

  constructor(app: IApp, authClient: AuthClient) {
    this.component = {
      info: Info,
      main: withProps({ admin: this, authClient: authClient })(Account as any),
    };
    this.app = app;
    this.links = [];
    this.reducer = {
      name: 'account',
      reducer,
    };
    this.app.addReducer(this.reducer);
    this.app.addRoute({
      path: '/account',
      component: this.component.main,
      protectedRoute: false,
    });
    this.addRoute({
      path: '/account/info',
      component: this.component.info,
      protectedRoute: false,
    });
  }

  start() {
    const sorted = this.links.sort((a, b) => a.order - b.order);
    sorted.map(link => this.app.store.dispatch(addMenuItem(link)));
  }
}
