import React, { useState } from 'react';
import './index.scss';
import PlaceComponent from '../Place/PlaceComponent';
import { pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

const SeasonTicketComponent = props => {
  const ticket = props.tickets[0];

  const isMobile = window.screen.width <= 990;

  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="season-ticket">
      <div
        className="season-ticket__title-section"
        style={{
          backgroundColor: props.colors.primary,
        }}
      >
        <div
          className="season-ticket__title-section__title"
          style={{
            color: props.colors.navigation_2,
          }}
        >
          {pathOr('', ['descriptor', 'title'], ticket)}
        </div>
      </div>
      <div className="season-ticket__body-section">
        <div className="season-ticket__body-section__tickets-container">
          {props.tickets.map((ticket, index) => {
            if (index <= (isMobile ? 1 : 3) || isOpen) {
              return (
                <PlaceComponent
                  colors={props.colors}
                  ticket={ticket}
                  key={ticket.id}
                  client={props.client}
                  userPhone={props.userPhone}
                  walletPassAvailable={
                    window.parametrize('REACT_APP_ALLOW_WALLETPASS') === 'true'
                  }
                />
              );
            } else return null;
          })}
        </div>
        {!isOpen && props.tickets.length > 4 ? (
          <div
            className="ticket__long-button"
            onClick={() => {
              setOpen(true);
            }}
          >
            <div className="ticket__long-button__icon"></div>
            <div className="ticket__long-button__text">
              {t('inventory.season_ticket_component.ticket__long-button__text')}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default SeasonTicketComponent;
