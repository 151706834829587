import { connect } from 'react-redux';
import * as actions from '../actions';

const mapStateToProps = state => {
  const reducer = () => state.reducer;
  const userState = reducer().account;
  return {
    name: userState.name,
  };
};
const mapDispatchToProps = dispatch => ({ ...actions, dispatch });

export default connect(mapStateToProps, mapDispatchToProps);
