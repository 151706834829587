import React, { useEffect, useState } from 'react';
import ProfileComponent from '../Profile/ProfileComponent';
import './index.scss';
import Cart from '../../Icons/Cart';
import Ticket from '../../Icons/Ticket';
import Smile from '../../Icons/Smile';
import AdditionalLogo from './Icons/AdditionalLogo';
import i18n, { switchLng, getSupportedLngs } from 'i18n';
import { useTranslation } from 'react-i18next';

const HeaderComponent = props => {
  const [isMenuActive, setMenuActive] = useState(false);
  const { t } = useTranslation();

  const isMobile = window.screen.width <= 840;

  const supportedLangs = getSupportedLngs();
  const renderLangSwitcher = () => {
    if (supportedLangs.length > 1) {
      return supportedLangs.map(lang => (
        <span
          className={`header__text-language ${
            i18n.language === lang ? 'header__text-language__active' : ''
          }`}
          onClick={() => switchLng(lang)}
        >
          {lang.toUpperCase()}
        </span>
      ));
    } else return null;
  };

  return (
    <>
      <div
        className={`header header__${window.parametrize('REACT_APP_CLUB')} ${
          isMenuActive ? 'header__mobile-menu-open' : ''
        }`}
      >
        <div className="header__left-container">
          <a
            href={window.parametrize('REACT_APP_URL_LOGO')}
            className="header__logo-container"
          >
            <div
              className={`header__logo-container__image header__logo-container__size-${window.parametrize(
                'REACT_APP_CLUB',
              )}`}
              style={{
                backgroundImage:
                  'url(' + window.parametrize('REACT_APP_LOGO_SRC') + ')',
              }}
            />
            {!!window.parametrize('REACT_APP_ADDITIONAL_LOGO_SRC').length ? (
              <AdditionalLogo
                url={window.parametrize('REACT_APP_ADDITIONAL_LOGO_SRC')}
                className={`header__logo-container__icon ${
                  isMenuActive ? 'header__logo-container__icon-inverted' : ''
                }`}
              />
            ) : (
              <span
                className={`header__logo-container__club ${
                  isMenuActive ? 'header__logo-container__icon-inverted' : ''
                }`}
              >
                {window.parametrize('REACT_APP_ADDITIONAL_LOGO_TEXT')}
              </span>
            )}
            {/* <div className="header__logo-container__text" /> */}
          </a>
          <div className={'header__display-container_language-container'}>
            {renderLangSwitcher()}
          </div>
        </div>
        {/* !!! */}

        <div className="header__display-container">
          {/* <div className="header__display-container__timer-container">
          <div className="header__display-container__timer-container__icon" />
          <div className="header__display-container__timer-container__text">
            10:23
          </div>
        </div> */}

          <div
            className={
              isMenuActive
                ? 'hidden'
                : 'header__display-container__item-container'
            }
            onClick={() => {
              const url = window.parametrize('REACT_APP_CART_URL');
              window.location.href = url;
            }}
          >
            <div className="header__display-container__item-container__icon-container">
              <Cart
                className="header__display-container__item-container__icon-container__cart-icon"
                color={props.colors.navigation_1}
              />
              {props.itemsCount !== 0 && (
                <div className="header__display-container__item-container__icon-container__counter">
                  {props.itemsCount}
                </div>
              )}
            </div>
            <div
              className="header__display-container__item-container__text"
              style={{
                color: props.colors.navigation_1,
              }}
            >
              {t(
                'header.header__display_container__item_container__text.shopping_cart',
              )}
            </div>
          </div>

          <div
            className={
              isMenuActive
                ? 'hidden'
                : 'header__display-container__item-container__shop'
            }
            onClick={() => {
              const url = window.parametrize('REACT_APP_SHOP_URL');
              window.location.href = url;
            }}
          >
            {!isMobile && (
              <Ticket
                color={props.colors.navigation_1}
                className="header__display-container__item-container__icon-container__shop-icon"
              />
            )}
            <div
              className="header__display-container__item-container__text"
              style={{
                color: props.colors.navigation_1,
              }}
            >
              {t(
                'header.header__display_container__item_container__text.tickets',
              )}
            </div>
          </div>

          <div
            className="header__display-container__user-container"
            style={{
              borderColor: 'rgba(50, 50, 50, 0.25)',
            }}
          >
            <Smile
              color={props.colors.primary}
              className="header__display-container__user-container__icon"
            />
            <div
              className="header__display-container__user-container__text"
              style={{
                color: props.colors.navigation_1,
              }}
            >
              {props.name}
            </div>
          </div>

          {/* <div className="header__display-container__menu-container"
        onClick={() => {
          setMenuActive(!isMenuActive)
        }}
        >

        </div> */}
          <div
            id="menu-icon"
            className={isMenuActive ? 'open mobile' : 'mobile'}
            onClick={() => {
              setMenuActive(!isMenuActive);
            }}
          >
            <span
              style={{
                backgroundColor: isMenuActive
                  ? '#ffffff'
                  : props.colors.navigation_1,
              }}
            ></span>
            <span
              style={{
                backgroundColor: isMenuActive
                  ? '#ffffff'
                  : props.colors.navigation_1,
              }}
            ></span>
            <span
              style={{
                backgroundColor: isMenuActive
                  ? '#ffffff'
                  : props.colors.navigation_1,
              }}
            ></span>
            <span
              style={{
                backgroundColor: isMenuActive
                  ? '#ffffff'
                  : props.colors.navigation_1,
              }}
            ></span>
          </div>
        </div>
      </div>
      <div
        className={isMenuActive ? 'menu-container__open' : 'menu-container'}
        style={{
          backgroundColor: props.colors.primary,
        }}
      >
        <div className="menu-container__inner-container">
          <div
            className="menu-container__inner-container__item"
            onClick={() => {
              const url = window.parametrize('REACT_APP_CART_URL');
              window.location.href = url;
            }}
          >
            <div className="header__display-container__item-container">
              <div
                className="header__display-container__item-container__icon-container"
                style={{
                  color: props.colors.navigation_2,
                }}
              >
                <Cart
                  className="header__display-container__item-container__icon-container__cart-icon"
                  color={props.colors.navigation_2}
                />
                {props.itemsCount !== 0 && (
                  <div className="header__display-container__item-container__icon-container__counter">
                    {props.itemsCount}
                  </div>
                )}
              </div>
            </div>
            <div
              className="menu-container__inner-container__item__text"
              style={{
                color: props.colors.navigation_2,
              }}
            >
              {t(
                'header.menu_container__inner_container__item__text.shopping_cart',
              )}
            </div>
          </div>
          <div
            className="menu-container__inner-container__item"
            onClick={() => {
              const url = window.parametrize('REACT_APP_SHOP_URL');
              window.location.href = url;
            }}
          >
            <div className="header__display-container__item-container">
              <div className="header__display-container__item-container__icon-container">
                <Ticket
                  color={props.colors.navigation_2}
                  className="header__display-container__item-container__icon-container__shop-icon"
                />
              </div>
            </div>
            <div
              className="menu-container__inner-container__item__text"
              style={{
                color: props.colors.navigation_2,
              }}
            >
              {t('header.menu_container__inner_container__item__text.tickets')}
            </div>
          </div>
          <div
            className="menu-container__inner-container__profile-container"
            style={{
              backgroundColor: props.colors.navigation_2,
            }}
          >
            <Smile
              color={props.colors.primary}
              className="header__display-container__user-container__icon"
            />
            <div
              className="menu-container__inner-container__profile-container__text"
              style={{
                color: props.colors.navigation_1,
              }}
            >
              {t(
                'header.menu-container__inner-container__profile-container__text',
              )}
            </div>
          </div>

          <ProfileComponent
            client={props.client}
            profile={props.profile}
            authClient={props.authClient}
            setName={props.setName}
            desktop={false}
            colors={props.colors}
          />
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
