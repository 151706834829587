import { useTranslation } from 'react-i18next';
import React from 'react';

const CustomQuery = ({
  request,
  loadingStyles,
  loadingClass,
  loadingComponent,
}) => {
  const { t } = useTranslation();
  if (request.loading) {
    return (
      <div style={loadingStyles} className={loadingClass}>
        {loadingComponent ? (
          loadingComponent()
        ) : (
          <i>{t('custom_query.loading_class.div')}</i>
        )}
      </div>
    );
  }

  if (request.error) {
    return (
      <code>
        <pre>{`${request.error}`}</pre>
      </code>
    );
  }
  return null;
};

export default CustomQuery;
