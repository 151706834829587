import gql from 'graphql-tag';

export const USER_REGISTRATION = gql`
  mutation registerUser($phone: String!, $seasonTicketVisibleIds: [String!]!) {
    seasonTicket {
      registerUserAndProlongSeasonTickets(
        phone: $phone
        seasonTicketVisibleIds: $seasonTicketVisibleIds
      )
    }
  }
`;

export const USER_SIMPLE_REGISTRATION = gql`
  mutation setSimpleRegistration($phone: String!) {
    registration {
      simpleUserRegistration(phone: $phone) {
        id
      }
    }
  }
`;

export const SEND_CONFIRMATION_EMAIL = gql`
  mutation confirmEmail($email: String!, $confirmationResultUrl: String!) {
    contacts {
      sendEmailConfirmationCode(
        email: $email
        confirmationResultUrl: $confirmationResultUrl
      ) {
        operationId
        nextTryAfterMsecs
      }
    }
  }
`;

export const RESTORE_PASSWORD = gql`
  mutation restorePassword($phone: String!, $captchaCode: String) {
    registration {
      restorePassword(phone: $phone, captchaCode: $captchaCode) {
        nextRestoreAfterMsecs
      }
    }
  }
`;
