export {};
declare global {
  interface Window {
    parametrize: any;
    less: any;
  }
}

window.parametrize = function(varName: string) {
  const varFromWindow = ((<any>window)[varName] || '').trim();
  const isVarFromWindowEmpty =
    varFromWindow.includes('${') || varFromWindow === '';
  return isVarFromWindowEmpty ? process.env[varName] : varFromWindow;
};

window.less = {
  async: false,
  env: window.parametrize('NODE_ENV'),
};
