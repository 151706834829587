import React from "react";
import { ReactComponent as HappyTicketsSvg } from "./happy-tickets.styled.svg";

function propsToCssVars(props?: HappyTicketsProps): React.CSSProperties {
  return {
    "--fan-theme_clubNavigation1": props?.styles?.fanThemeClubNavigation1,
    "--fan-theme_clubPrimaryLight": props?.styles?.fanThemeClubPrimaryLight
  } as React.CSSProperties;
}

export type HappyTicketsProps = {
  styles?: {
    fanThemeClubNavigation1?: string;
    fanThemeClubPrimaryLight?: string;
  };
};

/**
 * Generated component
 * Available CSS variables:
 * fan-theme_clubNavigation1,
 * fan-theme_clubPrimaryLight,
 **/
export default function HappyTickets(props?: HappyTicketsProps) {
  return <HappyTicketsSvg style={{ ...propsToCssVars(props) }} />;
}
