import React from 'react';
import BaseConfirmationProps from './BaseConfirmationProps';
import { Modal } from 'antd';
import 'antd/lib/modal/style';
import './Confirmation.scss';
import CloseIcon from './icon/closeIcon';
import { ButtonType } from 'antd/lib/button';

export default (props: BaseConfirmationProps) => {
  let hasBody = props.text || false;
  let okType: ButtonType = 'primary';

  switch (props.btnType) {
    case 'destructive': {
      okType = 'text';
      break;
    }
    case 'regular': {
      okType = 'primary';
      break;
    }
  }

  return (
    <Modal
      okType={okType}
      centered={true}
      className={'FanzillaConfirmation'}
      visible={props.visible}
      title={props.title}
      onCancel={props.onCancel}
      onOk={props.onAccept}
      okText={props.acceptButtonTitle}
      cancelText={props.cancelButtonTitle}
      bodyStyle={{
        display: hasBody ? undefined : 'none',
      }}
      closeIcon={<CloseIcon />}
    >
      {props.text}
    </Modal>
  );
};
