import React, { Reducer } from 'react';
import ReactDOM from 'react-dom';
import hoverBtnStyle from './hoverBtnStyle';
import { combineReducers, createStore } from 'redux';
import * as serviceWorker from '../serviceWorker';
import { IApp, IReducerContainer, ModuleRoute } from '../../common/src';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import { enhancer } from './store/configureStore';

import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';

import { isEmpty, pathOr } from 'ramda';

import { ConfigProvider, notification } from 'antd';

import { getErrorMessage } from './dictionary';
import contains from 'ramda/es/contains';
import { getSupportedLngs } from 'i18n';

const currentLngs = getSupportedLngs();

export class Application implements IApp {
  routes: ModuleRoute[];
  reducers: IReducerContainer[];
  store: any;
  graphQLErrors: any;
  networkErrors: any;

  constructor() {
    this.routes = [];
    this.reducers = [];
    this.graphQLErrors = [];
    this.networkErrors = [];
  }

  addRoute(route: ModuleRoute) {
    this.routes.push(route);
  }

  addReducer(reducer: any) {
    this.reducers.push(reducer);
  }

  configureStore(initialState: any) {
    const obj: any = {};
    if (this.reducers.length) {
      this.reducers.forEach((item: any) => (obj[item.name] = item.reducer));
      const reducer = combineReducers(obj) as Reducer<any, any>;
      return createStore(combineReducers({ reducer }), initialState, enhancer);
    }
  }

  setGraphQLErrors = (arr: any) => {
    this.graphQLErrors = arr;
  };
  setNetworkErrors = (arr: any) => {
    this.networkErrors = arr;
  };

  start(authClient: AuthClient, refreshAuthLink?: any) {
    hoverBtnStyle();

    this.store = this.configureStore({});
    const httpLink = {
      uri: window.parametrize('REACT_APP_GRAPHQL_ENDPOINT'),
    };

    const errorHandlerLink = onError(props => {
      if (pathOr(false, ['graphQLErrors', 'length'], props)) {
        this.setGraphQLErrors(props.graphQLErrors);
        pathOr([], ['graphQLErrors'], props).forEach((error: any) => {
          // const message = getErrorMessage(error);
          if (contains('e8d21919', error.message)) {
            authClient.logout();
            window.location.reload();
          }
          // if (!message.includes('uid=9bd114c9')) {
          // const { t } = useTranslation();
          //   notification.open({
          //     message: t('app_store.error_handler_link'),
          //     description: message,
          //   });
          // }
        });
      }
      if (!isEmpty(props.networkError)) {
        const message = pathOr(false, ['networkError', 'message'], props);
        if (message) {
          notification.open({
            message: 'NetworkError',
            description: getErrorMessage(props.networkError),
          });
        }
      }
    });
    const link = new HttpLink(httpLink);
    const middlewareLink = new ApolloLink((operation, forward: any) => {
      operation.setContext({
        headers: {
          authorization: authClient.credentials
            ? 'Bearer ' + authClient.credentials.idToken
            : '',
          'Accept-Language': currentLngs,
        },
      });
      return forward(operation);
    });

    const client = new ApolloClient({
      link: ApolloLink.from([
        errorHandlerLink,
        refreshAuthLink,
        middlewareLink,
        link,
      ]),
      cache: new InMemoryCache(),
    });

    const AppComponent = () => (
      <Provider store={this.store}>
        <ApolloProvider client={client}>
          <ConfigProvider
            theme={{
              token: {
                // Seed Token
                colorPrimary: window.parametrize('REACT_APP_CLUB_PRIMARY'),
                colorPrimaryHover: window.parametrize(
                  'REACT_APP_CLUB_PRIMARY_LIGHT',
                ),
              },
            }}
          >
            <Router
              basename={(
                window.parametrize('REACT_APP_BASE_PATH') || ''
              ).trim()}
            >
              <Switch>
                {this.routes.map((route: any, i: number) => {
                  return (
                    <Route
                      key={i}
                      path={route.path}
                      component={route.component}
                    />
                  );
                })}
              </Switch>
            </Router>
          </ConfigProvider>
        </ApolloProvider>
      </Provider>
    );

    ReactDOM.render(<AppComponent />, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
  }
}
