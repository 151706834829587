import React from 'react';

const Ticket = props => {
  return (
    <svg
      className={props.className}
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25689e-07 6.00001C1.65685 6.00001 3 7.34316 3 9.00001C3 10.6569 1.65685 12 4.70473e-07 12L-8.15946e-08 16C-3.65311e-08 17.1046 0.89543 18 2 18L20 18C21.1046 18 22 17.1046 22 16L22 12C20.3431 12 19 10.6569 19 9.00001C19 7.34316 20.3431 6.00001 22 6.00001L22 2C22 0.89543 21.1046 9.18945e-07 20 9.70676e-07L2 1.81368e-06C0.89543 1.86541e-06 -6.9782e-07 0.895433 -6.52756e-07 2L2.25689e-07 6.00001Z"
        />
      </mask>
      <path
        d="M2.25689e-07 6.00001L-2 6.00001L-2 8.00001L3.07283e-07 8.00001L2.25689e-07 6.00001ZM4.70473e-07 12L3.88878e-07 10L-2 10L-2 12L4.70473e-07 12ZM-8.15946e-08 16L-2 16L-2 16L-8.15946e-08 16ZM2 18L2 20L2 18ZM20 18L20 16L20 18ZM22 12L24 12L24 10L22 10L22 12ZM22 6.00001L22 8.00001L24 8.00001L24 6.00001L22 6.00001ZM-6.52756e-07 2L-2 2L-6.52756e-07 2ZM5 9.00001C5 6.23859 2.76142 4.00001 1.44094e-07 4.00001L3.07283e-07 8.00001C0.552285 8.00001 1 8.44773 1 9.00001L5 9.00001ZM5.52067e-07 14C2.76142 14 5 11.7614 5 9.00001L1 9.00001C1 9.5523 0.552285 10 3.88878e-07 10L5.52067e-07 14ZM2 16L2 12L-2 12L-2 16L2 16ZM2 16L2 16L-2 16C-2 18.2091 -0.20914 20 2 20L2 16ZM20 16L2 16L2 20L20 20L20 16ZM20 16L20 16L20 20C22.2091 20 24 18.2091 24 16L20 16ZM20 12L20 16L24 16L24 12L20 12ZM22 10C21.4477 10 21 9.5523 21 9.00001L17 9.00001C17 11.7614 19.2386 14 22 14L22 10ZM21 9.00001C21 8.44773 21.4477 8.00001 22 8.00001L22 4.00001C19.2386 4.00001 17 6.23859 17 9.00001L21 9.00001ZM20 2L20 6.00001L24 6.00001L24 2L20 2ZM20 2L20 2L24 2C24 -0.20914 22.2091 -2 20 -2L20 2ZM2 2L20 2L20 -2L2 -2L2 2ZM2 2L2 -2C-0.20914 -2 -2 -0.209137 -2 2L2 2ZM2 6.00001L2 2L-2 2L-2 6.00001L2 6.00001Z"
        fill={props.color}
        mask="url(#path-1-inside-1)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11.75C11.6904 11.75 12.25 12.3096 12.25 13C12.25 13.6904 11.6904 14.25 11 14.25C10.3096 14.25 9.75 13.6904 9.75 13C9.75 12.3096 10.3096 11.75 11 11.75Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 7.75C11.6904 7.75 12.25 8.30964 12.25 9C12.25 9.69036 11.6904 10.25 11 10.25C10.3096 10.25 9.75 9.69036 9.75 9C9.75 8.30964 10.3096 7.75 11 7.75Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3.75C11.6904 3.75 12.25 4.30964 12.25 5C12.25 5.69036 11.6904 6.25 11 6.25C10.3096 6.25 9.75 5.69036 9.75 5C9.75 4.30964 10.3096 3.75 11 3.75Z"
        fill={props.color}
      />
    </svg>
  );
};

export default Ticket;
