import React, { useState, useEffect } from 'react';
import './index.scss';
import HeaderComponent from './Header/HeaderComponent';
import ProfileComponent from './Profile/ProfileComponent';
import FooterComponent from '../Footer/FooterComponent';
import InventoryComponent from './Inventory/InventoryComponent';
import { GET_PROFILE, GET_CART } from '../graphql';
import { pathOr } from 'ramda';

const MainComponent = props => {
  const [profile, setProfile] = useState({});
  const [isLoaded, setLoaded] = useState(false);
  const [itemsCount, setItemsCount] = useState(0);

  const colors = {
    primary: window.parametrize('REACT_APP_CLUB_PRIMARY'),
    primaryLight: window.parametrize('REACT_APP_CLUB_PRIMARY_LIGHT'),
    background_1: window.parametrize('REACT_APP_CLUB_BG_1'),
    background_2: window.parametrize('REACT_APP_CLUB_BG_2'),
    background_3: window.parametrize('REACT_APP_CLUB_BG_3'),
    navigation_1: window.parametrize('REACT_APP_CLUB_NAVIGATION_1'),
    navigation_2: window.parametrize('REACT_APP_CLUB_NAVIGATION_2'),
  };

  useEffect(() => {
    const init = async () => {
      const res = await props.client.query({
        query: GET_PROFILE,
        fetchPolicy: 'no-cache',
      });

      const user = pathOr({}, ['data', 'users', 'getProfile'], res);
      const person = pathOr({}, ['person'], user);

      const name = pathOr('', ['name', 'ru'], person);
      props.setName(name);
      const surname = pathOr('', ['surname', 'ru'], person);

      const phone = pathOr('', ['login'], user);
      const email = (
        pathOr([], ['contacts'], person).find(item => item.type === 'EMAIL') ||
        {}
      ).value;

      const carRes = await props.client.query({
        query: GET_CART,
        fetchPolicy: 'no-cache',
      });

      const itemsCount = pathOr(
        [],
        ['data', 'order', 'getCurrent', 'items'],
        carRes,
      ).length;

      setProfile({
        name,
        surname,
        email,
        phone,
        id: user.id,
      });

      setItemsCount(itemsCount);

      setLoaded(true);
    };
    if (!isLoaded) {
      init();
    }
  }, [isLoaded]);
  return (
    <>
      <div
        className={`main team-background__${window.parametrize(
          'REACT_APP_CLUB',
        )}`}
      >
        {isLoaded ? (
          <div className="main__container">
            <HeaderComponent
              profile={profile}
              name={props.name}
              authClient={props.authClient}
              setName={props.setName}
              client={props.client}
              itemsCount={itemsCount}
              colors={colors}
            />
            <div className="main__body">
              <ProfileComponent
                client={props.client}
                profile={profile}
                authClient={props.authClient}
                setName={props.setName}
                desktop={true}
                colors={colors}
              />
              <InventoryComponent
                client={props.client}
                userPhone={profile.phone}
                colors={colors}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <FooterComponent colors={colors} />
    </>
  );
};

export default MainComponent;
