import React, { useState } from 'react';
import { Button, message, ConfigProvider } from 'antd';
import { Mutation } from 'react-apollo';
import { RESTORE_PASSWORD } from '../graphql';
import InputMask from 'react-input-mask';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

const LoginForm = props => {
  const { t } = useTranslation();
  const [stateProps, setState] = useState({
    showResetPasswordBlock: false,
    reCaptcha: false,
  });

  const proceedLogin = async ev => {
    try {
      await props.authClient.login(
        stateProps.userPhone,
        stateProps.userPassword,
      );
      window.location.href = window.location.origin + '/account/subscriptions';
    } catch (e) {
      if (e.message.includes('BAD_CREDENTIALS')) {
        message.error(t('auth.login_form.proceed_login'));
      }
      console.error(e);
    }
  };

  const restorePassword = (action, phone, captchaCode) => {
    if (phone.length === 11) {
      action({
        variables: {
          phone,
          captchaCode,
        },
        update: async () => {
          message.success(
            t('auth.login_form.restore_password.message_success'),
          );
          setState({ ...stateProps, showResetPasswordBlock: false });
        },
      });
    } else message.error(t('auth.login_form.restore_password.message_error'));
  };

  const handleReCaptcha = token => {
    setState({ ...stateProps, reCaptcha: token });
  };
  return (
    <>
      <div className="login">
        <div className="login__container">
          <div className="login__header">
            {stateProps.showResetPasswordBlock
              ? t('auth.login_form.login__header.password_recovery')
              : t('auth.login_form.login__header.personal_area')}
          </div>
          <div
            style={{
              width: '240px',
              margin: '0 auto',
            }}
          >
            {stateProps.showResetPasswordBlock ? (
              <>
                <Mutation mutation={RESTORE_PASSWORD}>
                  {act => (
                    <>
                      <InputMask
                        type="tel"
                        size="large"
                        name="phoneNumber"
                        placeholder="+7"
                        mask="+7(999)999-99-99"
                        maskChar="_"
                        className="login__telephone-input"
                        onKeyPress={async ev => {
                          if (ev.key === 'Enter') {
                            await restorePassword(act, stateProps.userPhone);
                          }
                        }}
                        onChange={ev => {
                          setState({
                            ...stateProps,
                            userPhone: ev.target.value
                              .replace(/\+7/, '7')
                              .replace(/[()_-]/g, ''),
                          });
                        }}
                      />
                      {window.parametrize('REACT_APP_RECAPTCHA_KEY') && (
                        <ReCAPTCHA
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px',
                          }}
                          sitekey={window.parametrize(
                            'REACT_APP_RECAPTCHA_KEY',
                          )}
                          onChange={handleReCaptcha}
                          hl="ru"
                        />
                      )}
                      <Button
                        id="LoginFormGetSmsButton"
                        className="login__proceed-button person-info__btn-exit"
                        type="primary"
                        block
                        disabled={!stateProps.reCaptcha}
                        onClick={async ev => {
                          await restorePassword(
                            act,
                            stateProps.userPhone,
                            stateProps.reCaptcha,
                          );
                        }}
                      >
                        {t('auth.login_form.button.receive_sms')}
                      </Button>
                    </>
                  )}
                </Mutation>
              </>
            ) : (
              <>
                <InputMask
                  type="tel"
                  size="large"
                  name="phoneNumber"
                  placeholder="+7"
                  mask="+7(999)999-99-99"
                  maskChar="_"
                  className="login__telephone-input"
                  onKeyPress={async ev => {
                    if (ev.key === 'Enter') {
                      await proceedLogin();
                    }
                  }}
                  onChange={ev => {
                    setState({
                      ...stateProps,
                      userPhone: ev.target.value
                        .replace(/\+7/, '7')
                        .replace(/[()_-]/g, ''),
                    });
                  }}
                />
                <input
                  className="login__password-input"
                  placeholder={t('auth.login_form.input_placeholder')}
                  type="password"
                  onKeyPress={async ev => {
                    if (ev.key === 'Enter') {
                      await proceedLogin();
                    }
                  }}
                  onChange={ev => {
                    setState({ ...stateProps, userPassword: ev.target.value });
                  }}
                />
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#000000',
                      boxShadow: null,
                    },
                  }}
                >
                  <Button
                    id="LoginFormSignInButton"
                    type="primary"
                    className="login__proceed-button person-info__btn-exit"
                    block
                    onClick={async ev => {
                      await proceedLogin();
                    }}
                  >
                    {t('auth.login_form.button.come_in')}
                  </Button>
                </ConfigProvider>
              </>
            )}
          </div>
          <p
            className="login__go-to-reset"
            onClick={ev => {
              setState({
                ...stateProps,
                showResetPasswordBlock: !stateProps.showResetPasswordBlock,
              });
            }}
          >
            {stateProps.showResetPasswordBlock
              ? t('auth.login_form.p.back')
              : t('auth.login_form.p.forgot_your_password')}
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
