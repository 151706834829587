import gql from 'graphql-tag';

export const GET_SHORT_PROFILE = gql`
  query {
    users {
      getProfile {
        id
        login
        person {
          name
          surname
          contacts {
            type
            value
            isConfirmed
          }
        }
      }
    }
  }
`;

export const GET_ORDER = gql`
  query {
    order {
      getCurrent {
        items {
          id
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_ID = gql`
  query getSubscriptionId($id: ID!) {
    seasonTicket {
      getByVisibleId(visibleId: $id) {
        id
      }
    }
  }
`;

export const GET_PROFILE_INFO = gql`
  query {
    users {
      getProfile {
        id
        person {
          name
          surname
          patronymic
          birthday
          contacts {
            type
            value
            isConfirmed
          }
        }
      }
    }
  }
`;
