import React from 'react';

const AdditionalLogo = (props: { url: string; className?: string }) => {
  return (
    <div className={props.className}>
      <div
        style={{
          backgroundImage: props.url
            ? `url(${props.url})`
            : window.parametrize('REACT_APP_ADDITIONAL_LOGO_SRC'),
        }}
      />
    </div>
  );
};

export default AdditionalLogo;
