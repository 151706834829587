import React from "react";

const CloseIcon = props => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.854951 13.3627C0.390906 13.8266 0.368809 14.655 0.866 15.13C1.34109 15.627 2.18079 15.6049 2.64484 15.141L8.00346 9.78387L13.3621 15.141C13.8372 15.616 14.6548 15.627 15.1299 15.13C15.6271 14.655 15.616 13.8266 15.1409 13.3516L9.7823 7.99448L15.1409 2.64838C15.616 2.16237 15.6271 1.34499 15.1299 0.870029C14.6548 0.372975 13.8372 0.384021 13.3621 0.858984L8.00346 6.21613L2.64484 0.858984C2.18079 0.395066 1.34109 0.372975 0.866 0.870029C0.368809 1.34499 0.390906 2.17342 0.854951 2.63733L6.21357 7.99448L0.854951 13.3627Z"
        fill="#323232"
      />
    </svg>
  );
};

export default CloseIcon;
