import React from 'react';

import MainComponent from './Main/MainComponent';
import connect from './containers/connect';
import { compose } from 'ramda';

const AccountComponent = props => {
  return (
    <>
      <MainComponent
        client={props.client}
        authClient={props.authClient}
        name={props.name}
        setName={name => {
          props.dispatch(props.setName(name));
        }}
      />
    </>
  );
};

export default compose(connect)(AccountComponent);
